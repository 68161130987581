import React, { createContext, useState, ReactNode, FC } from "react";
import { Recipe } from "../../types/recipes";
import { Patient } from "../../types/patients";
import { ReportType } from "../../types/reports";

export type SectionsType =
  | "Home"
  | "Patient Records"
  | "Patient Logs"
  | "Appointments"
  | "Recipes"
  | "Settings"
  | ""
  | "add-recipes"
  //Todo: change this to recipe instead of recipes
  | "edit-recipes"
  | "add-patient"
  | "edit-patient"
  | "Reports";

type DashboardContextType = {
  activeSection: SectionsType;
  setActiveSection: (section: SectionsType) => void;
  editRecipe?: Recipe | undefined;
  setEditRecipe: (recipe: Recipe | undefined) => void;
  editPatient?: Patient | undefined;
  setEditPatient: (patient: Patient | undefined) => void;
  editReport?: ReportType | undefined;
  setEditReport: (report: ReportType | undefined) => void;
};

const initialContext: DashboardContextType = {
  activeSection: "Home",
  setActiveSection: () => {},
  editRecipe: undefined,
  setEditRecipe: () => {},
  editPatient: undefined,
  setEditPatient: () => {},
  editReport: undefined,
  setEditReport: () => {},
};

// Creating the context with default values
export const DashboardContext =
  createContext<DashboardContextType>(initialContext);

export const DashboardProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [activeSection, setActiveSection] = useState<SectionsType>("Home");
  const [editRecipe, setEditRecipe] = useState<Recipe | undefined>(undefined);
  const [editPatient, setEditPatient] = useState<Patient | undefined>(
    undefined
  );
  const [editReport, setEditReport] = useState<ReportType | undefined>(
    undefined
  );

  return (
    <DashboardContext.Provider
      value={{
        activeSection,
        setActiveSection,
        editRecipe,
        setEditRecipe,
        editPatient,
        setEditPatient,
        editReport,
        setEditReport,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};
