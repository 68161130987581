import React, { useContext } from "react";
import Button from "../../common/components/button";
import { InputField } from "../../common/components/index";
import { LabelText } from "../../common/components/index";
import { Dropdown } from "../../common/components/index";

import { Field, FieldArray, Form, Formik } from "formik";
import CSS from "./patients.module.css";
import { Patient } from "../../../types/patients";
import { DashboardContext } from "../../dashboard/dashboard-context";
import { Table, TableRow } from "../../common/components/table";

import {
  AddPatient as AddPatientAPI,
  UpdatePatient,
} from "../../../api/patients-api";
import { toast } from "react-toastify";

export const AddPatient = () => {
  const { setActiveSection, editPatient } = useContext(DashboardContext);
  return (
    <Formik
      initialValues={editPatient ? editPatient : ({} as Patient)}
      onSubmit={(values: Patient) => {
        if (editPatient) {
          UpdatePatient(values);
          setActiveSection("Patient Records");
          toast.success("Patient updated successfully");
        } else {
          AddPatientAPI(values);
          setActiveSection("Patient Records");
          toast.success("Patient added successfully");
        }
      }}
    >
      {({ values }) => (
        <Form
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className={CSS.form}>
            <span className={CSS.formField}>
              <LabelText text="Name" />
              <Field as={InputField} name="name" />
            </span>
            <span className={CSS.formRow}>
              <span className={CSS.formField}>
                <LabelText text="Age" />
                <Field width={120} name="age" as={InputField} />
              </span>
              <span className={CSS.formField}>
                <LabelText text="Blood Type" />
                <Field
                  name="bloodType"
                  label="blood"
                  options={["A", "B", "AB", "O"]}
                  as={Dropdown}
                />
              </span>
              <span className={CSS.formField}>
                <LabelText text="Weight" />
                <Field width={120} name="weight" as={InputField} />
              </span>
            </span>

            <span className={CSS.formRow}>
              <span className={CSS.formField}>
                <LabelText text="Phone Number" />
                <Field width={240} name="phoneNumber" as={InputField} />
              </span>

              <span className={CSS.formField}>
                <LabelText text="Email" />
                <Field width={240} name="email" as={InputField} />
              </span>
              <span className={CSS.formField}>
                <LabelText text="Address" />
                <Field name="address" as={InputField} />
              </span>
            </span>

            <span className={CSS.formField}>
              <LabelText text="Referred By" />
              <Field name="referredBy" as={InputField} />
            </span>

            <div className={CSS.horizontalDivider} />
            <span className={CSS.formField}>
              <LabelText text="History" />
              <Table
                headerRow={[
                  { data: "Sr#", width: 3 },
                  { data: "Name", width: 45 },
                  { data: "Duration Days (Optional)", width: 25 },
                  { data: "Action", width: 10 },
                ]}
                height={90}
              >
                <FieldArray
                  name="history"
                  render={(arrayHelpers) => (
                    <div>
                      {values.history?.map((history, index) => (
                        <div key={index}>
                          <TableRow
                            columns={[
                              { data: index + 1, width: 3 },
                              {
                                child: (
                                  <span>
                                    <Field
                                      name={`history[${index}].name`}
                                      as={InputField}
                                      placeholder="e.g. Cough, Cold, Fever etc."
                                    />
                                  </span>
                                ),
                                width: 45,
                              },
                              {
                                child: (
                                  <span>
                                    <Field
                                      name={`history[${index}].duration`}
                                      as={InputField}
                                      placeholder="(Days)"
                                    />
                                  </span>
                                ),
                                width: 25,
                              },
                              {
                                child: (
                                  <span className={CSS.tableButton}>
                                    <Button
                                      type="button"
                                      onClick={() => arrayHelpers.remove(index)}
                                      mode="secondary"
                                      label="Remove"
                                    />
                                  </span>
                                ),
                                width: 10,
                              },
                            ]}
                          />
                        </div>
                      ))}

                      <div className={CSS.tableButton}>
                        <Button
                          onClick={() =>
                            arrayHelpers.push({ name: "", age: "" })
                          }
                          mode="primary"
                          label="+"
                        />
                      </div>
                    </div>
                  )}
                />
              </Table>

              {/* <Field name="history" as={InputField} /> */}
            </span>
            <span className={CSS.formField}>
              <LabelText text="Diagnosis" />
              <Field multiline name="diagnosis" as={InputField} />
            </span>
            <span className={CSS.formField}>
              <LabelText text="Allergies" />
              <Field name="allergies" as={InputField} />
            </span>
          </div>
          <span className={CSS.buttonsRow}>
            {editPatient ? (
              <Button type="submit" label="Update Patient" />
            ) : (
              <Button type="submit" label="Add Patient" />
            )}

            <Button
              mode="secondary"
              label="Cancel"
              onClick={() => {
                setActiveSection("Patient Records");
              }}
            />
          </span>
        </Form>
      )}
    </Formik>
  );
};
