import { Recipe } from "../types/recipes";

export const ingredientsData = [
  // Fruits
  { value: "banana", label: "Banana - FRUIT" },
  { value: "apple", label: "Apple - FRUIT" },
  { value: "orange", label: "Orange - FRUIT" },
  { value: "strawberry", label: "Strawberry - FRUIT" },
  { value: "grape", label: "Grape - FRUIT" },
  { value: "mango", label: "Mango - FRUIT" },
  { value: "pineapple", label: "Pineapple - FRUIT" },
  { value: "blueberry", label: "Blueberry - FRUIT" },
  { value: "watermelon", label: "Watermelon - FRUIT" },
  { value: "cherry", label: "Cherry - FRUIT" },
  { value: "avocado", label: "Avocado - FRUIT" },

  // Meats
  { value: "chicken", label: "Chicken - MEAT" },
  { value: "beef", label: "Beef - MEAT" },
  { value: "pork", label: "Pork - MEAT" },
  { value: "lamb", label: "Lamb - MEAT" },
  { value: "turkey", label: "Turkey - MEAT" },
  { value: "duck", label: "Duck - MEAT" },
  { value: "goat", label: "Goat - MEAT" },
  { value: "bison", label: "Bison - MEAT" },
  { value: "venison", label: "Venison - MEAT" },
  { value: "rabbit", label: "Rabbit - MEAT" },

  // Seafood

  { value: "salmon", label: "Salmon - SEAFOOD" },
  { value: "shrimp", label: "Shrimp - SEAFOOD" },
  { value: "tuna", label: "Tuna - SEAFOOD" },
  { value: "crab", label: "Crab - SEAFOOD" },
  { value: "lobster", label: "Lobster - SEAFOOD" },
  { value: "octopus", label: "Octopus - SEAFOOD" },
  { value: "mussels", label: "Mussels - SEAFOOD" },
  { value: "clams", label: "Clams - SEAFOOD" },
  { value: "squid", label: "Squid - SEAFOOD" },
  { value: "oyster", label: "Oyster - SEAFOOD" },

  // Vegetables
  { value: "carrot", label: "Carrot - VEGETABLE" },
  { value: "broccoli", label: "Broccoli - VEGETABLE" },
  { value: "spinach", label: "Spinach - VEGETABLE" },
  { value: "potato", label: "Potato - VEGETABLE" },
  { value: "tomato", label: "Tomato - VEGETABLE" },
  { value: "onion", label: "Onion - VEGETABLE" },
  { value: "cucumber", label: "Cucumber - VEGETABLE" },
  { value: "pepper", label: "Pepper - VEGETABLE" },
  { value: "lettuce", label: "Lettuce - VEGETABLE" },
  { value: "cabbage", label: "Cabbage - VEGETABLE" },

  // Nuts
  { value: "almond", label: "Almond - NUT" },
  { value: "walnut", label: "Walnut - NUT" },
  { value: "cashew", label: "Cashew - NUT" },
  { value: "pecan", label: "Pecan - NUT" },
  { value: "pistachio", label: "Pistachio - NUT" },
  { value: "hazelnut", label: "Hazelnut - NUT" },
  { value: "macadamia", label: "Macadamia - NUT" },
  { value: "brazilnut", label: "Brazil Nut - NUT" },
  { value: "pine_nut", label: "Pine Nut - NUT" },
  { value: "chestnut", label: "Chestnut - NUT" },

  // Seeds
  { value: "chia", label: "Chia - SEED" },
  { value: "flax", label: "Flax - SEED" },
  { value: "pumpkin", label: "Pumpkin - SEED" },
  { value: "sunflower", label: "Sunflower - SEED" },
  { value: "sesame", label: "Sesame - SEED" },
  { value: "hemp", label: "Hemp - SEED" },
  { value: "poppy", label: "Poppy - SEED" },
  { value: "quinoa", label: "Quinoa - SEED" },
  { value: "mustard", label: "Mustard - SEED" },
  { value: "caraway", label: "Caraway - SEED" },
];

export const RecipesData: Recipe[] = [
  {
    id: "1",
    name: "Banana and Apple Smoothie",
    ingredients: [
      { ingredientName: "Banana", quantity: 1 },
      { ingredientName: "Apples", quantity: 1, unit: "kg" },
      { ingredientName: "Yogurt", quantity: 0.5, unit: "l" },
      { ingredientName: "Banana", quantity: 1 },
      { ingredientName: "Apples", quantity: 1, unit: "kg" },
      { ingredientName: "Yogurt", quantity: 0.5, unit: "l" },
    ],
    instructions: "Blend all the ingredients together and serve chilled.",
    dateCreated: "2021-09-01",
    tags: ["Lack of Good Fats", "Lack of Vitamins"],
  },
  {
    id: "2",
    name: "Pumpkin Soup",
    ingredients: [
      { ingredientName: "Pumpkin", quantity: 1, unit: "kg" },
      { ingredientName: "Onion", quantity: 1 },
      { ingredientName: "Garlic", quantity: 2, unit: "quantity" },
    ],
    instructions:
      "Boil the pumpkin, onion, and garlic together. Blend the mixture and serve hot.",
    dateCreated: "2021-09-02",
    tags: ["Immune System", "Fermentation"],
  },
  {
    id: "3",
    name: "Pasta with Tomato Sauce",
    ingredients: [
      { ingredientName: "Pasta", quantity: 500, unit: "g" },
      { ingredientName: "Tomatoes", quantity: 4 },
      { ingredientName: "Garlic", quantity: 2, unit: "quantity" },
    ],
    instructions:
      "Boil the pasta. Cook the tomatoes and garlic together to make the sauce. Serve hot.",
    dateCreated: "2021-09-03",
    tags: ["Dehydration", "Liver", "Cholesterol"],
  },
  {
    id: "4",
    name: "Chicken Curry",
    ingredients: [
      { ingredientName: "Chicken", quantity: 500, unit: "g" },
      { ingredientName: "Onion", quantity: 1 },
      { ingredientName: "Tomatoes", quantity: 2 },
    ],
    instructions:
      "Cook the chicken with onions and tomatoes. Serve hot with rice or bread.",
    dateCreated: "2021-09-04",
    tags: ["Candida", "Parasites", "B12", "Iron"],
  },
  {
    id: "5",
    name: "Vegetable Stir Fry",
    ingredients: [
      { ingredientName: "Broccoli", quantity: 1, unit: "quantity" },
      { ingredientName: "Carrots", quantity: 2 },
      { ingredientName: "Bell Pepper", quantity: 1 },
    ],
    instructions:
      "Stir fry all the vegetables together. Serve hot with soy sauce.",
    dateCreated: "2021-09-05",
    tags: ["Minerals", "Hormones", "D3"],
  },
];
