import React, { useContext, useEffect, useState } from "react";
import { Recipe } from "../../../types/recipes";
import CSS from "./recipes.module.css";
import { InputField, LabelText } from "../../common/components";
import Button from "../../common/components/button";
import { DashboardContext } from "../../dashboard/dashboard-context";
import { Table, TableRow } from "../../common/components/table";

import { ReportType } from "../../../types/reports";
import { FormikErrors } from "formik";
import { AppContext } from "../../app-context/app-context";

type ViewRecipesProps = {
  path?: string;
  setFieldValue?: (
    field: string,
    value: Recipe
  ) => Promise<void | FormikErrors<ReportType>>;
  onClose?: () => void;
};
export const Recipes = ({ path, setFieldValue, onClose }: ViewRecipesProps) => {
  const { allRecipes: RecipesData } = useContext(AppContext);

  const [filteredRecipes, setFilteredRecipes] = useState<Recipe[]>(RecipesData);
  const { setActiveSection, setEditRecipe } = useContext(DashboardContext);

  let isReportView: boolean = false;

  // Reusing the view recipes component for report view as well for the purpose of selecting a recipe
  // while writing a report for a patient.
  if (path && setFieldValue) {
    isReportView = true;
  }

  // Clearing up saved recipe in edit recipe when a recipe is opened, but then cancelled and user is
  // navigated back to list all recipes page.

  useEffect(() => {
    setEditRecipe(undefined);
  }, []);

  return (
    <div className={CSS.form}>
      {isReportView && <h2>Select a Recipe</h2>}
      <div className={CSS.allRecipesHeader}>
        <span className={CSS.formField}>
          <LabelText text="Search" />
          <InputField
            width={520}
            placeholder="Search for recipes (Name, Findings, Diagnostics, Ingredients, Instructions, Date)"
            onChange={(value) => {
              if (value.target.value && value.target.value !== "") {
                const filtered = RecipesData.filter(
                  (recipe) =>
                    recipe.name
                      .toLowerCase()
                      .includes(value.target.value.toLowerCase()) ||
                    recipe.tags?.some((tag) =>
                      tag
                        .toLowerCase()
                        .includes(value.target.value.toLowerCase())
                    ) ||
                    recipe.ingredients.some((ingredient) =>
                      ingredient.ingredientName
                        .toLowerCase()
                        .includes(value.target.value.toLowerCase())
                    ) ||
                    recipe.instructions
                      ?.toLowerCase()
                      .includes(value.target.value.toLowerCase()) ||
                    recipe.dateCreated === value.target.value
                );
                setFilteredRecipes(filtered);
              } else setFilteredRecipes(RecipesData);
            }}
          />
        </span>

        {!isReportView && (
          <Button
            label="Add new recipe"
            onClick={() => {
              setActiveSection("add-recipes");
            }}
          />
        )}
      </div>
      <div className={CSS.allRecipes}>
        <Table
          headerRow={[
            { data: "Sr.", width: 5 },
            { data: "Recipe Name", width: 20 },
            { data: "Ingredients", width: 30 },
            { data: "Tags", width: 20 },
            { data: "Date Added", width: 20 },
            { data: "Instructions", width: 20 },
          ]}
          height={80}
        >
          {filteredRecipes.map((recipe, index) => (
            <span
              onClick={
                isReportView
                  ? () => {
                      setFieldValue && setFieldValue(path!, recipe);
                      onClose && onClose();
                    }
                  : () => {
                      setEditRecipe && setEditRecipe(recipe);
                      setActiveSection("edit-recipes");
                    }
              }
            >
              <TableRow
                columns={[
                  { data: index + 1 + "", width: 5 },
                  { data: recipe.name, width: 20 },
                  {
                    data: recipe.ingredients
                      .map(
                        (ingredient) =>
                          `${ingredient.quantity}  ${ingredient.unit ?? ""} - ${
                            ingredient.ingredientName
                          }  
                        `
                      )
                      .join(", "),
                    width: 30,
                  },
                  {
                    data: recipe.tags?.join(", ") ?? "-",
                    width: 20,
                  },
                  { data: recipe.dateCreated ?? "-", width: 20 },
                  { data: recipe.instructions ?? "-", width: 20 },
                ]}
              />
            </span>
          ))}
        </Table>
      </div>
    </div>
  );
};
